body{
  font-family: "raleway", sans-serif;
font-size: 0.9em;
letter-spacing: 0.01em;
  line-height: 140%;
	font-weight: 500;
  font-variant: normal;
	text-transform: none;
	word-spacing: 0.07em;
  text-decoration: none;
  color: rgb(51, 51, 51);
margin-left: 1em;
margin-right: 1em;
background: white;
}

@include breakpoint(xlarge) {
 body {
margin-left: 0em;
margin-right: 0em;
}
}


 a:link {
    font-weight: 500;
  }
     
     a:visited {
      font-weight: 500;
     }
     
     a:hover {
      font-weight: 500;
     }
     
     a:active {
       color:rgb(50, 65, 87);
     } 


     .linktext {
      font-weight: 500;
      text-transform: none;
      
    a:link {
      font-weight: 500;
    border-bottom: 0.07em dotted rgba(0, 0, 0, 0.363);
    }
    
    a:visited {
      font-weight: 500;
      border-bottom: 0.07em dotted rgba(0, 0, 0, 0.363);
    }
    
    a:hover {
      border-bottom: 0.08em dotted rgb(0, 0, 0);
    }
    
    a:active {
      border-bottom: 0.08em dotted rgb(0, 0, 0);
    }
    }

     .large {
       font-size: 120%;
     }


    .textohlink {
     border: none;

    a:link {
      border: none;
    }
    a:visited {
     border: none;
    }
    a:hover {
      border: none;
    }
    a:active {
      color: white;
     border: none;
    }
    }


h1 {
  font-family: "raleway", sans-serif;
  font-size: 1.5em;
  line-height: 80%;
  font-weight: 600;
  letter-spacing: 0rem;
  line-height: 2rem;
  text-transform: uppercase;
  word-spacing: .05rem;
}

@include breakpoint(medium) {
  h1 {
    font-size: 2.0em;
    line-height: 120%;
}
}  




h2 {
  font-family: "raleway", sans-serif;
  font-size: 1em;
  letter-spacing: 0.01em;
  line-height: 130%;
	font-weight: 700;
}


h3 {
  font-family: "raleway", sans-serif;
  font-size: 1.03em;
  font-weight: 600;
  letter-spacing:0.02em;
  line-height: 140%;
  text-transform: none;
  word-spacing: .05rem;
}


h4 {
  font-family: "raleway", sans-serif;
  font-size: 1em;
  font-weight: 400;
  letter-spacing:0rem;
  line-height: 140%;
  text-transform: none;
  word-spacing: .05rem;
}


.stelle {
  font-family: "raleway", sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  letter-spacing:0rem;
  line-height: 140%;
  text-transform: none;
  word-spacing: .05rem;
  color: red;
}


.red{
color: red;  
color: rgba(252, 0, 0, 1);  
}


.red-text{
color: rgb(219, 1, 1);  
font-weight: 700;
}

.weiss {
  color: white;
  font-weight: 700;

  a:link {
    color: white;
  }
  a:visited {
    color: white;
  }
  a:hover {
    color: white;
  }
  a:active {
    color: white;
  }
}

p {
  line-height: 140%;
}



.background-footer {
  background-attachment: scroll;
  background-image: url('../img/fixed/htg-bottom.jpg');
  background-repeat: repeat-x, repeat-x;
  background-size: auto, auto;
  margin-top: 1rem;
  padding: 1rem 0;
  border-top: 1px solid lightgray;  
  margin-left: -2em;
  margin-right: -2em;
  padding:2em;

  bottom: 0em;
  }

.padding-top-db {
  padding-top: 1.5em;
}

.padding-bottom-db {
  padding-bottom: 1em;
}

.padding-bottom-db-2 {
  padding-bottom: 3em;
}

.padding-bottom-db-3 {
  padding-bottom: 20em;
}

.all-wrapper {
  overflow: visible;

  .all-inner {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

   
header,
footer {
  flex-shrink: 0;
}

.slider {
  margin-bottom: 0;
}

.slider-parent {
  min-width: 0;
  min-height: 0;
}



/* Slider small */
.slick-initialized {
  img {
    background-size: contain;
     overflow: hidden;
    background-position: center 50%; 
    display: table;
    }

  display: block;
  top: -1em;
  margin-bottom: 2rem;
  margin-left: -2em;
  margin-right: -2em;

height: auto;

}


@include breakpoint(medium) {
  .slick-initialized {
    img {
      height: auto;
      }
    }
    top: 0em;
    } 




.slick-prev:before,
.slick-next:before {
  color: $black;
}

.referenzen-slider-nav {
  img {
    padding: .113rem;
   /* weight: 6.5rem;
    height: auto;*/
  }

   .slick-center img {
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    transition: all 300ms ease;
    background-color: $white;
  }
}
.card-db {
  background-color: light-grey;
  border: 1px solid lightgray;
  color: #0a0a0a;
  padding: 0rem;
	height: 200px;
	margin-top: 3rem;
margin-bottom: 2rem;
}



.card {
	background-color: #f6f6f6;
  border: 0.9rem dotted light-grey;
  color: #0a0a0a;
  padding: 0rem;
  margin-bottom: 2em;
}

.card-section {
  min-height: 4.0em;
}


.card-divider {
   color: black;
  display: flex;
  margin-bottom: 0;
	margin-top: -2.5rem;
	border-top: 0em solid lightgray;
  padding: 0.7em 0.5em 0.5em 1.0em;
  background-color: rgba(255, 255, 255, 0.6);
	
   a {
      background-color: transparent;
      color: black;
	  font-weight:600;
    }	
}

.card-divider-db {
  color: black;
  display: flex;
  margin-bottom: 0;
	margin-top: 1rem;
	border-top: 0.08em solid lightgray;
  padding: 0.2rem 0.5rem 0.2rem 1.0rem;
  background-color: rgba(255, 255, 255, 0.5);
  background-color:  #da0c1d;

	
   a {
      color: black;
      color: white;
	  font-weight:600;
    }	
}

.callout-db-farbig {
background-color: #f6f6f6;
border: 0.05em dotted lightgray;
margin-top: 0em;
margin-bottom: 1.0rem;
padding: 0.5em;


   a {
      color: black;
    font-weight:500;
    } 
}


.callout-db {
background-color: white;
border: 0.05em dotted lightgray;
margin-bottom: 0.6rem;
padding: 0.2rem 0rem 0rem 1rem;
color: black;

   a {
      color: black;
    font-weight:500;
    } 
}

.callout {
  background-color: transparent;
  color:white;
  border: none;
  padding: 0.3em;
  padding-right: 3em;
  margin: 0em;
}

.teaser-stellenangebot {
  line-height: 120%;
  position: absolute;
  margin-top: 13em;
  background-color:  #da0c1def;
  border: 0.09em solid rgba(255, 255, 255, 0.829);
  border-radius: 0em 0.5em 0.5em 0em;
  margin-left: -2em;
  padding-left: 2em;
  padding-top: 1em;
  color: white;
  z-index: 1;

width: 20em;
font-size: 90%;

 @include breakpoint(medium) {
  margin-top: 21em;
}

 @include breakpoint(large) {
font-size: 100%;
margin-top: 17em;
}

 @include breakpoint(xlarge) {
padding-left: 3em;
}
}
           
  .icon-size {
   font-size: 150%;
   line-height: 150%;
   }

           
  .close-button {
    color: rgba(255, 255, 255, 0.884);
    top: -0.5rem;
  }


.button-db {
  color: white;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding: 0.5em;
  padding-right: 0.9em;
  background-color: #fc0000;
  font-size: 97%;
  line-height: 100%;
  border-radius: 0.2em;
  border: 0.01em solid #d60606; 
  display: table;

 a:link {
  color: white;
  font-weight: 700;
}
   
   a:visited {
    color: white;
    font-weight: 700;
   }
   
   a:hover {
    color: white;
    font-weight: 800;
   }
   
   a:active {
    color: white;
    font-weight: 800;
   } 
}  


.button-fein {
  color: white;
  /* margin-bottom: 0.5em; */
  /* margin-top: 0.5em; */
  padding: 0.3em;
  /* padding-right: 0.9em; */
  background-color: #ffffff;
  font-size: 97%;
  line-height: 100%;
  border-radius: 0.2em;
  border: 0.01em solid #5f5e5e;
  display: table;


 a:link {
  color: rgb(0, 0, 0);
  font-weight: 600;
}
   
   a:visited {
    color: rgb(0, 0, 0);
    font-weight: 600;
   }
   
   a:hover {
    color: rgb(0, 0, 0);
    font-weight: 800;
   }
   
   a:active {
    color: rgb(0, 0, 0);
    font-weight: 800;
   } 
}  


.button-bewerbung {
 color: rgb(255, 255, 255);
  padding: 0.5em;
  padding-top: 0.5em;
  background-color: rgba(252, 0, 0, 1);
  font-size: 94%;
  line-height: 95%;
  border-radius: 0.2em;
  border: 0.02em solid rgb(0, 0, 0);
  display: flex;

 a:link {
  color: white;
  font-weight: 700;
}
   
   a:visited {
    color: white;
    font-weight: 700;
   }
   
   a:hover {
    color: white;
    font-weight: 800;
   }
   
   a:active {
    color: white;
    font-weight: 800;
   } 
}  

.link-db{
color: lightgray; 
margin-bottom: 0.6rem; 

   a {
      color: black;
    font-weight:500;
    border: 0.05em dotted lightgray;
    padding: 0.2rem 1.5rem 0.2rem 0.5rem;
    } 
}

.logo {
  @include show-for(large);
  margin-left: 1em;
}

.logo-small {
  img {
    margin-right: .313rem;
    width: 14em;
  }
    background-color: transparent;
  padding: .5rem 0;
  margin-left: 0rem;
  margin-bottom: 1rem;
}


@include breakpoint(medium) {
  .logo-small  img {
      width: 16em;
  }
     }  

@include breakpoint(large) {
  .logo-small  img {
      width: 17em;
      margin-top:0.5em;
  }
     }  



.outline {
	background-color: rgb(245, 243, 243);
border: 0.08em solid grey;
}

.line-rot {
	background: #da0c1d;
	height: 0.03em;
margin-top:1em;
  margin-bottom: 2em;
}

.line-grau {
	background-color: rgb(209, 209, 209);
	height: 0.05em;
margin-top:1em;
  margin-bottom: 2em;
}


.line1{
  padding-bottom: 1em;
  }
  
.unterschrift {
  img {width: 50%}
}

.sp {
  img {width: 50%}
  }

.menu-button {
background-color: $secondary-color;
padding: 3rem;
}

.sticky {
  z-index: 100;
}

.background-gradient {
background-image: url('../img/fixed/htg-header.png')
 /* background-color: red;*/
}


.nav-layer {
 background-color: rgb(255, 255, 255);
  border-bottom: 0.03em solid rgba(221, 220, 220, 0);
  height: 8em;
  margin-left: -2em;
  margin-right: -2em;
  padding-top: 1.5em;
  padding-left: 2em;
  padding-right: 2em;
  z-index: 2000;
}

@include breakpoint(medium) {
  .nav-layer {
   height: 8em;
     }
     }  


.sticky {
  position: relative;
  right: auto;
  left: auto;
  height: 133px;
background-color: rgba(194, 58, 58, 0.6);
background-color: transparent;
}

.title-bar {
  margin-top: -4.1rem;
  padding-right: 0rem;
  background-color: transparent;

  .menu-icon-wrapper {
    border: 1.7px solid $secondary-color;
    display: inline;
    margin-right: .5rem;
    margin-top: -10px;
    padding: .3rem .2rem .2rem .3rem;
    background-color: rgba(255, 255, 255, 0.5);
   }

  .menu-icon {
    &:hover {
      &::after {
        background-color: $secondary-color;
        box-shadow: 0 7px 0 $secondary-color, 0 14px 0 $secondary-color;
      }
    }
  }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #000000;
    -webkit-box-shadow: 0 7px 0 #000000, 0 14px 0 #000000;
    box-shadow: 0 7px 0 #000000, 0 14px 0 #000000;
    content: '';
}
}


.top-bar {
  nav {
    width: 100%;

    li {
      line-height: 2.5rem;
      margin-bottom: .2em;
      background-color: transparent;
      width: 100%;
    }

    a {
      background-color: $secondary-color;
      padding-right: .3em;
      color: $white;
      /*padding: .75rem 1rem;*/
    }

    .submenu {
      background-color: transparent;
      text-align: right;
      margin-right: .0rem;
      margin-top: .1rem;
      margin-bottom: .1rem;
      max-width: 100%;

      li {
        margin-bottom: .2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .menu.vertical {
      top: .3em;
      background-color: transparent;
      padding-left: 7em;
      /*width: 100%;*/
      padding-right: 0em;
    }

    .submenu-toggle {
      background-color: $secondary-color;
      height: 2.3rem;


      &::after {
        border-color: $white transparent transparent;
      }
    }
  }

}


.top-bar, .top-bar ul {
  background-color: #e6e6e600;
}

    .accordion-menu .is-accordion-submenu a {
    /*padding: 0.7rem 1.2rem;*/
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom:0.7em;
  }


.kundenzufriedenheit {
margin-top: -5em;  
}

  .bottom-position {
    bottom: 0px;
  }

footer {
  background-attachment: scroll;
  background-image: url('../img/fixed/htg-bottom.jpg');
  background-repeat: repeat-x, repeat-x;
  background-size: auto, auto;
  margin-top: 1rem;
  padding: 1rem 0;
  border-top: 1px solid lightgray;

  .menu.simple {
    align-items: flex-start;

    li {
      line-height: 1.7rem;
      margin: 0;
      position: relative;

      &::before {
        background-repeat: no-repeat;
        /*content: '';*/
        display: inline-block;
        height: 1.471rem;
        margin: 0 .4rem -.5rem 0;
        width: 1.471rem;
      }

      &.tel {
        &::before {
          background: url('../img/icons/icon-telefon-schw.png');
        }
      }

      &.fax {
        &::before {
          background: url('../img/icons/icon-fax-schw.png');
        }
      }

      &.mail {
        &::before {
          background: url('../img/icons/icon-mail-schw.png');
          height: .882rem;
          margin-bottom: -.15rem;
        }
      }

      a {
        display: inline-block;
      }
    }
  }
}


@include breakpoint(medium) {

	.card {
	margin-bottom: 2rem;
}
}


@include breakpoint(large) {

	.card {
	margin-bottom: 3rem;
}


  .top-bar {
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    z-index: 100;
    background-color: transparent;
    top: 0em;
    margin-right: 0em;

    


    ul ul {
      background-color: transparent;
    }


    nav {
      .dropdown.menu {

        top: -3.0em;
        background-color: light-grey;
        margin-left: 10rem;
        margin-right: 0rem;
        max-width: 90%;
        
        a {
          background-color: transparent;
          color: $primary-color;
          font-family: Raleway, sans-serif;
          font-weight: 600;
          text-transform: uppercase;
          padding-right: .4em;
          padding-left: .4em;
          padding-bottom: .5em;
          

          &:hover {
           background-color: $secondary-color;
            color: white;
        padding-right: .5rem;
          padding-left: .5rem;
          padding-top: .8rem;
          padding-bottom: .3em;
          }
        }

        li {
          width: inherit;
          width: auto;


          &.menu-text {
            background: none;
            width: 30%;
            margin-left: -1.5rem;

            a {
              padding: 0;
              margin-top: -.188rem;


              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        
        .submenu {
          max-width: inherit;
          background-color: transparent;
          border: none;
          margin-top: .2rem;
          padding-right: 0rem;
          padding-top: 0rem;
          text-align: left;


          a {
            color: white;
            margin-right: .5em;
            margin-left: .1em;
            padding: 0.3em;
            text-transform: none;
            width:10em;
            text-align: left;


            &::before {
              color: white;
              content: '\00bb\00bb';
              font-size: 1.2rem;
              left: .5rem;
              letter-spacing: -1px;
              line-height: 1rem;
              padding: .2rem 0;
              position: absolute;
              top: 0;
            }

            &:hover {
              color: $white;

              &::before {
                color: $white;
              }
            }
          }
        }
      }

      .is-active > a {
        background-color: $secondary-color;
        color: $white;
      }

      &.large-horizontal > .opens-right > .is-dropdown-submenu,
      &.large-horizontal > .opens-left > .is-dropdown-submenu {
        top: 3rem;
      }

      .is-dropdown-submenu-item {
        background-color: $secondary-color;
        top: 0em;
       max-width: auto;
        padding-left: 2rem;
        position: relative;
        z-index: 2000;
      }
    }
  }


  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    visibility: hidden;
}


  #content {
    margin-top: 4em;
    margin-bottom: 6em;
  }

  .top-bar {
    position: relative;
    width: 100%;

    .menu-text {
      width: inherit;
    }
  }

  footer {
    .menu.simple {

      li {
        padding-left: 3rem;
      }
    }
  }
}


.social-media {
  img{
    width: 2em;
    margin-right: 0.5em;
  }
}

.icon1 {
  font-size: 1.2em;
  color: rgb(145, 143, 143);
  margin-right: 0.3em;
  line-height:1.8em;
}

.hidden {
  display: none;
  visibility: hidden;
}