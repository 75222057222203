.product-image-gallery {
  background-color: $white;
  padding: 1.5rem;
}

.pdp-product-image {
  margin-bottom:30px;
  height: 30em;
  overflow: hidden;
}

.product-thumbs {
  
  a {
    margin-left: 8px;
    margin-right: 8px;
    padding: 0 !important;
    
    img {
      @include thumbnail;
      border-radius: 3px;
      width: 50px;
      height: 50px;
      border: none;
    }
  }
}
